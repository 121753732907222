export const project = 'cgworks'
export const queue = 'production-my-renderer'
export const location = 'asia-northeast1'

export const INTERIOR = 'interior'
const EXTERIOR = 'exterior'
export const DETAILED = 'detailed'

export const defaultPrompt
  = 'Masterpiece, high quality, best quality, authentic, super detail, render v-ray'
export const negative_prompt
  = '(normal_quality), (low_quality), (lowest_quality), ((nsfw)), ((nipples)), ((pubic hair)), ((nude)), ((bare navel)), (exposed skin), ((exposed chest)), bad anatomy,long_body,mutated hands,missing arms,extra_arms,extra_legs,bad hands,missing_limb,disconnected_limbs,extra_fingers,missing fingers,liquid fingers,ugly face,deformed eyes,cropped, picture, sketch, lowres, text, signature, watermark, username, sketch, logo, blurred, noise, UnrealisticDream'

export const defaultNumberOfImages = 12

export const cannyControlNetObj = {
  input_image: '',
  module: 'canny',
  model: 'control_v11p_sd15_canny [d14c016b]',
  resize_mode: 'Scale to Fit (Inner Fit)',
  lowvram: false,
  processor_res: 512,
  threshold_a: 100,
  threshold_b: 200,
  weight: 1.0,
  control_mode: 1,
}

export const depthControlNetObj = {
  input_image: '',
  module: 'depth',
  model: 'control_v11f1p_sd15_depth [cfd03158]',
  resize_mode: 'Scale to Fit (Inner Fit)',
  lowvram: false,
  processor_res: 512,
  weight: 1.0,
  control_mode: 2,
}

export const structureType = {
  interior: { en: INTERIOR, ja: '内観' },
  exterior: { en: EXTERIOR, ja: '外観' },
}

export const selectLineDetail = [
  { value: { en: 'detailed', ja: '3D/写真' }, url: '/images/detail.webp' },
  { value: { en: 'rough', ja: 'スケッチ' }, url: '/images/rough.webp' },
]

export const plans = {
  trial: {
    plan: 'trial',
    rendering: { title: 'レンダリング数：', amount: '3回' },
    freeDownloadPerMonth: '画像のダウンロード',
    downloadReset: 'ダウンロード回数を毎月リセット',
    partialEditPerMonth: '部分レンダリング機能',
  },
  lite: {
    plan: 'lite',
    rendering: { title: 'レンダリング数：', amount: '無制限' },
    freeDownloadPerMonth: '画像のダウンロード',
    downloadReset: 'ダウンロード回数を毎月リセット',
    partialEditPerMonth: '部分レンダリング機能',
  },
  standard: {
    plan: 'standard',
    rendering: { title: 'レンダリング数：', amount: '無制限' },
    freeDownloadPerMonth: { title: '画像のダウンロード：', amount: '５回/月' },
    downloadReset: 'ダウンロード回数を毎月リセット',
    partialEditPerMonth: '部分レンダリング機能',
  },
  professional: {
    plan: 'professional',
    rendering: { title: 'レンダリング数：', amount: '無制限' },
    freeDownloadPerMonth: { title: '画像のダウンロード：', amount: '無制限' },
    downloadReset: 'ダウンロード回数無制限',
    partialEditPerMonth: '部分レンダリング機能',
  },
}

export const productionUrl = {
  cgbank: 'https://cgbank.jp/cgbank',
}
